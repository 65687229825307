<template>
  <div>
    <template
      v-if="
        control.widgetId === 'assetLinkEditor' ||
          control.widgetId === 'assetLinksEditor'
      "
    >
      <h4 v-if="field.name">{{ field.name }}:</h4>
      <button
        v-if="!loading"
        :class="[
          'btn',
          value && (value.sys?.id || (isArray && value.length > 0))
            ? 'btn-primary'
            : 'btn-secondary',
          'w-100',
          'mb-3'
        ]"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="`#collapse_link_${field.id}`"
        aria-expanded="false"
        :aria-controls="`collapse_link_${field.id}`"
        :disabled="disabled"
      >
        {{
          value && (value.sys?.id || (isArray && value.length > 0))
            ? isArray
              ? "Выбраны элемент(ы)"
              : "Выбран элемент: "
            : "Не выбрано"
        }}
        {{ getSelected() }}
      </button>
      <button
        v-else
        class="btn btn-secondary w-100 mb-3"
        type="button"
        disabled
      >
        <span>Загрузка...</span>
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
      <div class="collapse" :id="`collapse_link_${field.id}`">
        <div class="d-flex justify-content-center flex-wrap gap-2">
          <div
            class="card"
            style="width: 15em"
            v-for="asset in assets"
            :key="asset.sys.id"
          >
            <div
              class="ratio ratio-4x3 card-img-top"
              :style="{
                background: `url(${
                  asset.fields.file && isImage(asset)
                    ? 'https:' + asset.fields.file[$route.params.locale].url
                    : '/img/icons/android-chrome-512x512.png'
                }) center/100%`,
                'background-size': 'cover'
              }"
            />
            <div class="card-body">
              <h5 class="card-title">
                {{
                  asset.fields.title
                    ? asset.fields.title[$route.params.locale]
                    : "Без названия"
                }}
              </h5>
              <p class="card-text">
                {{
                  asset.fields.description
                    ? asset.fields.description[$route.params.locale]
                    : "Без описания"
                }}
                <br />
              </p>
            </div>
            <button
              type="button"
              @click.stop.prevent="select(asset.sys.id)"
              :class="[
                'btn',
                'm-2',
                isSelected(asset.sys.id) ? 'btn-success' : 'btn-primary'
              ]"
              data-bs-toggle="collapse"
              :data-bs-target="!isArray && `#collapse_link_${field.id}`"
              aria-expanded="false"
              :aria-controls="!isArray && `collapse_link_${field.id}`"
              :disabled="disabled"
            >
              {{ isSelected(asset.sys.id) ? "Выбрано" : "Выбрать" }}
            </button>
            <div class="card-footer">
              <small class="text-muted"
                >Последнее обновление
                {{
                  $moment(asset.sys.updatedAt)
                    .locale("ru")
                    .fromNow()
                }}</small
              >
            </div>
            <div class="card-footer" v-if="asset.fields.file">
              <small class="text-muted">
                {{ asset.fields.file[$route.params.locale]?.fileName }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else> Неизвестный тип виджета: {{ control }}. </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assets: [],
      loading: true,
      isArray: false
    };
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    control: {
      type: Object,
      required: true
    },
    value: {
      type: undefined,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  async mounted() {
    if (this.control.widgetId === "assetLinksEditor") {
      this.isArray = true;
    }
    const client = this.$createClient();
    const space = await client.getSpace(this.$route.params.space);
    const enviroment = await space.getEnvironment(this.$route.params.env);
    this.assets = await enviroment.getAssets().then(e => e.items);
    this.loading = false;
  },
  methods: {
    isImage(asset) {
      return asset.fields.file
        ? asset.fields.file[this.$route.params.locale].contentType.startsWith(
            "image/"
          )
        : false;
    },
    isSelected(id) {
      if (this.isArray) {
        return this.value?.some(e => e?.sys?.id === id);
      } else {
        return this.value?.sys?.id === id;
      }
    },
    getSelected() {
      if (this.isArray || !this.value || !this.value.sys.id) return "";
      return this.assets.find(e => e.sys.id === this.value.sys.id)
        ? this.assets.find(e => e.sys.id === this.value.sys.id).fields.title
          ? this.assets.find(e => e.sys.id === this.value.sys.id).fields.title[
              this.$route.params.locale
            ]
          : "Без названия"
        : "Элемент недоступен";
    },
    select(id) {
      if (this.isArray) {
        if (this.isSelected(id)) {
          this.$emit(
            "update:value",
            this.value.filter(e => e.sys.id !== id)
          );
        } else {
          this.$emit("update:value", [
            ...this.value,
            {
              sys: {
                type: "Link",
                linkType: "Asset",
                id
              }
            }
          ]);
        }
      } else {
        this.$emit("update:value", {
          sys: {
            type: "Link",
            linkType: "Asset",
            id
          }
        });
      }
    }
  },
  emits: ["update:value"]
};
</script>
