<template>
  <div>
    <template v-if="control.widgetId === 'boolean'">
      <h4>{{ field.name }}:</h4>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          :name="`${field.id}_radio`"
          required
          @change="$emit('update:value', true)"
          :checked="value === true"
        />
        <label class="form-check-label">
          Да
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          :name="`${field.id}_radio`"
          required
          @change="$emit('update:value', false)"
          :checked="value === false"
        />
        <label class="form-check-label">
          Нет
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          :name="`${field.id}_radio`"
          required
          @change="$emit('update:value', null)"
          :checked="![false, true].includes(value) && !field.required"
          :disabled="field.required"
        />
        <label class="form-check-label">
          Не указано
        </label>
      </div>
    </template>
    <template v-else> Неизвестный тип виджета: {{ control }}. </template>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    control: {
      type: Object,
      required: true
    },
    value: {
      type: undefined,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["update:value"]
};
</script>
