<template>
  <div>
    <div
      class="modal fade"
      :id="`delete_assets`"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Удаление элемента</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Вы действительно хотите удалить элемент "{{ toDelete }}"? Это
            действие необратимо.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Отмена
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="confirmDelete"
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      :id="`create_assets`"
      tabindex="-1"
      aria-labelledby="createModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog">
        <form class="modal-content" @submit.prevent="submit">
          <div class="modal-header">
            <h5 class="modal-title" id="createModalLabel">Создание элемента</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="clear"
              :disabled="uploadState !== 0"
            ></button>
          </div>
          <div class="modal-body">
            <div class="file-upload-container">
              <label
                for="file-upload"
                class="custom-file-upload btn btn-primary"
              >
                {{
                  createFile
                    ? `Выбран файл: ${createFile.name}`
                    : "Нажмите на поле или перетащите файл"
                }}
              </label>
              <input
                id="file-upload"
                type="file"
                ref="file"
                required
                @change="handleFile"
              />
            </div>
            <input
              type="text"
              class="form-control mb-2"
              v-model="createTitle"
              placeholder="Заголовок (обязательно)"
              required
              maxlength="256"
            />
            <input
              type="text"
              class="form-control mb-2"
              v-model="createDescription"
              placeholder="Краткое описание"
              maxlength="256"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="clear"
              :disabled="uploadState !== 0"
            >
              Отмена
            </button>
            <button
              type="submit"
              class="btn btn-success"
              :disabled="uploadState !== 0"
            >
              Создать
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="loading" class="center">
    <div
      :class="[
        'spinner-border',
        loadingClasses[Math.floor(Math.random() * loadingClasses.length)]
      ]"
      style="width: 3rem; height: 3rem;"
      role="status"
    />
    <h5>{{ state }}</h5>
  </div>
  <div v-else-if="valid">
    <div class="container-fluid">
      <div class="d-flex justify-content-end m-3">
        <button
          class="btn btn-success"
          data-bs-toggle="modal"
          :data-bs-target="`#create_assets`"
        >
          Добавить
        </button>
      </div>
      <div class="d-flex justify-content-around flex-wrap gap-2">
        <div
          class="card"
          style="width: 15em"
          v-for="asset in assets"
          :key="asset.sys.id"
        >
          <div
            class="ratio ratio-4x3 card-img-top"
            :style="{
              background: `url(${
                asset.fields.file && isImage(asset)
                  ? 'https:' + asset.fields.file[$route.params.locale].url
                  : '/img/icons/android-chrome-512x512.png'
              }) center/100%`,
              'background-size': 'cover'
            }"
          />
          <div class="card-body">
            <h5 class="card-title">
              {{
                asset.fields.title
                  ? asset.fields.title[$route.params.locale]
                  : "Без названия"
              }}
            </h5>
            <p class="card-text">
              {{
                asset.fields.description
                  ? asset.fields.description[$route.params.locale]
                  : "Без описания"
              }}
              <br />
            </p>
          </div>
          <button
            type="button"
            :class="['btn', 'm-2', 'btn-danger']"
            data-bs-toggle="modal"
            :data-bs-target="`#delete_assets`"
            @click="preDelete(asset)"
          >
            Удалить
          </button>
          <div class="card-footer">
            <small class="text-muted"
              >Последнее обновление
              {{
                $moment(asset.sys.updatedAt)
                  .locale("ru")
                  .fromNow()
              }}</small
            >
          </div>
          <div class="card-footer" v-if="asset.fields.file">
            <small class="text-muted">
              {{ asset.fields.file[$route.params.locale]?.fileName }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="center">
    Ошибка
  </div>
</template>

<script>
export default {
  data() {
    return {
      assets: [],
      loading: true,
      valid: false,
      toDelete: null,
      loadingClasses: [
        "text-primary",
        "text-secondary",
        "text-success",
        "text-danger",
        "text-warning",
        "text-info",
        "text-dark"
      ],
      state: "Инициализация...",
      createFile: null,
      createTitle: "",
      createDescription: "",
      uploadState: 0
    };
  },
  props: {
    enviroment: {
      required: true,
      type: Object
    },
    space: {
      required: true,
      type: Object
    }
  },
  mounted() {
    this.update();
  },
  methods: {
    async update() {
      this.loading = true;
      try {
        this.state = "Получаем список ассетов...";
        this.assets = await this.enviroment.getAssets().then(e => e.items);
        this.valid = true;
        this.state = "Готово!";
      } catch (error) {
        this.valid = false;
        throw error;
      } finally {
        this.loading = false;
      }
    },
    isImage(asset) {
      return asset.fields.file
        ? asset.fields.file[this.$route.params.locale].contentType.startsWith(
            "image/"
          )
        : false;
    },
    preDelete(entry) {
      this.toDelete = entry;
    },
    async confirmDelete() {
      if (this.toDelete.isPublished()) await this.toDelete.unpublish();
      await this.toDelete.delete();
      this.toDelete = null;
      await this.update();
    },
    handleFile() {
      const change =
        this.createTitle?.length < 1 ||
        this.createTitle === this.createFile?.name;
      this.createFile = this.$refs.file.files[0];
      if (change) {
        this.createTitle = this.createFile.name;
      }
    },
    clear() {
      this.createFile = null;
      this.createTitle = "";
      this.createDescription = "";
      this.$refs.file.value = null;
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = e => {
          resolve(e.target.result);
        };
        reader.onerror = () => {
          reject();
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async submit() {
      this.uploadState = 1
      const bin = await this.readFile(this.createFile);
      console.log(bin, this.space);
      const upload = await this.enviroment.createUpload({
        file: bin,
        contentType: this.createFile.type,
        fileName: this.createFile.name
      });
      const asset = await this.enviroment.createAsset({
        fields: {
          title: {
            "en-US": this.createTitle
          },
          description: {
            "en-US": this.createDescription
          },
          file: {
            "en-US": {
              fileName: this.createFile.name,
              contentType: this.createFile.type,
              uploadFrom: {
                sys: {
                  type: "Link",
                  linkType: "Upload",
                  id: upload.sys.id
                }
              }
            }
          }
        }
      });
      await asset.processForLocale("en-US", { processingCheckWait: 2000 });
      await this.update();
      this.uploadState = 0
    }
  }
};
</script>

<style scoped>
input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.file-upload-container {
  position: relative;
  margin: 1em;
  border: 2px solid #ccc;
  height: 200px;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
