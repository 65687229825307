<template>
  <div v-if="loading" class="center">
    <div
      :class="[
        'spinner-border',
        loadingClasses[Math.floor(Math.random() * loadingClasses.length)]
      ]"
      style="width: 3rem; height: 3rem;"
      role="status"
    />
    <h5>{{ state }}</h5>
  </div>
  <div v-else-if="valid">
    <div
      class="modal fade"
      :id="`deleteModal_${contentType}`"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Удаление элемента</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Вы действительно хотите удалить элемент "{{
              toDelete
                ? type2name
                  ? toDelete.fields[type2name[toDelete.sys.contentType.sys.id]]
                    ? toDelete.fields[
                        type2name[toDelete.sys.contentType.sys.id]
                      ][$route.params.locale]
                    : "Без названия"
                  : "Без названия"
                : ""
            }}"? Это действие необратимо.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Отмена
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="confirmDelete"
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row mt-2 mb-2 justify-content-end" v-if="!!contentType">
        <div class="col col-auto">
          <router-link
            :to="
              `/dashboard/${$route.params.space}/${$route.params.env}/${$route.params.locale}/${contentType}/new`
            "
            class="btn btn-success"
            >Создать контент</router-link
          >
        </div>
      </div>
      <div
        class="row mb-2"
        v-for="entry in entries"
        :key="entry.sys.id"
      >
        <div class="col">
          <div class="card">
            <div class="card-body d-flex justify-content-between flex-wrap">
              <h5 class="card-title">
                {{
                  type2name
                    ? entry.fields[type2name[entry.sys.contentType.sys.id]]
                      ? entry.fields[type2name[entry.sys.contentType.sys.id]][
                          $route.params.locale
                        ]
                      : "Без названия"
                    : "Без названия"
                }}
              </h5>
              <div>
                <router-link
                  :to="
                    `/dashboard/${$route.params.space}/${$route.params.env}/${$route.params.locale}/${entry.sys.contentType.sys.id}/edit/${entry.sys.id}`
                  "
                  class="btn btn-primary m-1"
                  >Редактировать</router-link
                >
                <button
                  class="btn btn-warning m-1"
                  v-if="entry.isPublished() && !entry.isUpdated()"
                  @click="unPublish(entry)"
                >
                  Снять с публикации
                </button>
                <button
                  class="btn btn-warning m-1"
                  v-else
                  @click="publish(entry)"
                >
                  Опубликовать
                </button>
                <button
                  class="btn btn-outline-danger m-1"
                  data-bs-toggle="modal"
                  :data-bs-target="`#deleteModal_${contentType}`"
                  @click="preDelete(entry)"
                >
                  Удалить
                </button>
              </div>
            </div>
            <div class="card-footer">
              <small class="text-muted"
                >Последнее обновление
                {{
                  $moment(entry.sys.updatedAt)
                    .locale("ru")
                    .fromNow()
                }}</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="center">
    Ошибка
  </div>
</template>

<script>
export default {
  data() {
    return {
      entries: [],
      client: null,
      loading: true,
      valid: false,
      toDelete: null,
      loadingClasses: [
        "text-primary",
        "text-secondary",
        "text-success",
        "text-danger",
        "text-warning",
        "text-info",
        "text-dark"
      ],
      state: "Инициализация..."
    };
  },
  props: {
    enviroment: {
      required: true,
      type: Object
    },
    contentType: {
      required: true,
      type: String
    },
    type2name: {
      required: true,
      type: Object,
      default: null
    }
  },
  mounted() {
    this.update();
  },
  methods: {
    preDelete(entry) {
      this.toDelete = entry;
    },
    async confirmDelete() {
      if (this.toDelete.isPublished()) await this.toDelete.unpublish();
      await this.toDelete.delete();
      this.$emit("updateEntries");
      this.toDelete = null;
    },
    async update() {
      this.loading = true;
      this.client = this.$createClient();
      try {
        this.state = "Получаем список контента...";
        this.entries = await this.enviroment
          .getEntries({ content_type: this.contentType || undefined })
          .then(e => e.items);
        this.valid = true;
        this.state = "Готово!";
      } catch (error) {
        this.valid = false;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async publish(entry) {
      await entry.publish();
      this.$emit("updateEntries");
    },
    async unPublish(entry) {
      console.log(entry)
      await entry.unpublish();
      this.$emit("updateEntries");
    }
  }
};
</script>

<style scoped>
@media (max-width: 576px) {
  .card-body {
    flex-direction: column;
  }
}
</style>
