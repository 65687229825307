<template>
  <div>
    <template
      v-if="
        control.widgetId === 'singleLine' || control.widgetId === 'tagEditor'
      "
    >
      <label v-if="field.name" class="form-label"
        ><h4>{{ field.name }}:</h4></label
      >
      <input
        class="form-control"
        :required="field.required"
        @input="$emit('update:value', $event.target.value)"
        :maxlength="field.type === 'Symbol' ? '256' : '50000'"
        :disabled="disabled"
        :value="value"
      />
    </template>
    <template
      v-else-if="['markdown', 'richTextEditor'].includes(control.widgetId)"
    >
      <label v-if="field.name" class="form-label"
        ><h4>{{ field.name }}:</h4></label
      ><br />
      <div
        class="alert alert-danger"
        role="alert"
        v-if="this.control.widgetId === 'richTextEditor'"
      >
        Внимание! Тип этого поля - RichText, его поддержка в этой панели пока на ранней стадии, будьте готовы к очень странным багам!
      </div>
      <textarea
        class="form-control"
        ref="mde"
        :value="
          this.control.widgetId === 'richTextEditor' ? rt2md(value) : value
        "
        :required="field.required"
        :disabled="disabled"
      ></textarea>
    </template>
    <template v-else-if="control.widgetId === 'numberEditor'">
      <label v-if="field.name" class="form-label"
        ><h4>{{ field.name }}:</h4></label
      >
      <input
        class="form-control"
        :required="field.required"
        @input="$emit('update:value', Number($event.target.value))"
        type="number"
        :step="field.type === 'Integer' ? '1' : '0.00000001'"
        :disabled="disabled"
        :value="value"
      />
    </template>
    <template v-else> Неизвестный тип виджета: {{ control }}. </template>
  </div>
</template>

<script>
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import SimpleMDE from "simplemde";
import "simplemde/dist/simplemde.min.css";

export default {
  data() {
    return {
      mde: null
    };
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    control: {
      type: Object,
      required: true
    },
    value: {
      type: undefined,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["update:value"],
  mounted() {
    if (!["markdown", "richTextEditor"].includes(this.control.widgetId)) return;
    this.mde = new SimpleMDE({
      element: this.$refs.mde,
      spellChecker: false
    });
    this.mde.codemirror.on("change", async () => {
      this.$emit(
        "update:value",
        this.control.widgetId === "richTextEditor"
          ? await richTextFromMarkdown(this.mde.value())
          : this.mde.value()
      );
    });
  },
  methods: {
    rt2md(doc) {
      return documentToHtmlString(doc, {
        renderMark: {
          [MARKS.BOLD]: text => `**${text}**`,
          [MARKS.ITALIC]: text => `*${text}*`,
          [MARKS.UNDERLINE]: text => `${text}`,
          [MARKS.CODE]: text => `${text}`
        },
        renderNode: {
          [BLOCKS.HEADING_1]: (node, next) => `# ${next(node.content)}`,
          [BLOCKS.HEADING_2]: (node, next) => `## ${next(node.content)}`,
          [BLOCKS.HEADING_3]: (node, next) => `### ${next(node.content)}`,
          [BLOCKS.HEADING_4]: (node, next) => `#### ${next(node.content)}`,
          [BLOCKS.HEADING_5]: (node, next) => `##### ${next(node.content)}`,
          [BLOCKS.HEADING_6]: (node, next) => `###### ${next(node.content)}`,
          [BLOCKS.PARAGRAPH]: (node, next) => `${next(node.content)}`,
          [BLOCKS.EMBEDDED_ENTRY]: (node, next) => `${next(node.content)}`,
          [BLOCKS.UL_LIST]: (node, next) => `${next(node.content)}`,
          [BLOCKS.OL_LIST]: (node, next) => `${next(node.content)}`,
          [BLOCKS.LIST_ITEM]: (node, next) => `\n* ${next(node.content)}`,
          [BLOCKS.QUOTE]: (node, next) => `> ${next(node.content)}`
        }
      });
    }
  }
};
</script>
