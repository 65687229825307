<template>
  <div v-if="loading" class="center">
    <div
      :class="[
        'spinner-border',
        loadingClasses[Math.floor(Math.random() * loadingClasses.length)]
      ]"
      style="width: 3rem; height: 3rem;"
      role="status"
    />
    <h5>{{ state }}</h5>
  </div>
  <div v-else-if="valid">
    <form class="container mb-4" @submit.prevent="submit">
      <div class="row mt-2">
        <router-link
          class="close-link"
          :to="
            `/dashboard/${$route.params.space}/${$route.params.env}/${$route.params.locale}/${contentType}`
          "
        >
          <h5 class="close-h5">&lt;</h5>
        </router-link>
      </div>
      <div class="row mb-2 mt-4">
        <h3>Редактирование контента типа "{{ contentTypeData.name }}"</h3>
      </div>
      <div class="row mb-3" v-for="field in fields" :key="field.id">
        <div class="col">
          <TextInput
            :field="field"
            :control="interfaceData.getControlForField(field.id)"
            :disabled="disabled"
            v-model:value="form[field.id][$route.params.locale]"
            v-if="
              ['Number', 'Integer', 'Text', 'Symbol', 'RichText'].includes(
                field.type
              )
            "
          />
          <LinkInput
            :field="field"
            :control="interfaceData.getControlForField(field.id)"
            :disabled="disabled"
            v-model:value="form[field.id][$route.params.locale]"
            v-else-if="
              ['Link'].includes(field.type) && field.linkType === 'Entry'
            "
          />
          <AssetInput
            :field="field"
            :control="interfaceData.getControlForField(field.id)"
            :disabled="disabled"
            v-model:value="form[field.id][$route.params.locale]"
            v-else-if="
              ['Link'].includes(field.type) && field.linkType === 'Asset'
            "
          />
          <ArrayInput
            :field="field"
            :control="interfaceData.getControlForField(field.id)"
            :disabled="disabled"
            v-model:value="form[field.id][$route.params.locale]"
            v-else-if="['Array'].includes(field.type)"
          />
          <DateInput
            :field="field"
            :control="interfaceData.getControlForField(field.id)"
            :disabled="disabled"
            v-model:value="form[field.id][$route.params.locale]"
            v-else-if="['Date'].includes(field.type)"
          />
          <BooleanInput
            :field="field"
            :control="interfaceData.getControlForField(field.id)"
            :disabled="disabled"
            v-model:value="form[field.id][$route.params.locale]"
            v-else-if="['Boolean'].includes(field.type)"
          />
          <LocationInput
            :field="field"
            :control="interfaceData.getControlForField(field.id)"
            :disabled="disabled"
            v-model:value="form[field.id][$route.params.locale]"
            v-else-if="['Location'].includes(field.type)"
          />
          <template v-else>
            <h4>
              Внимание! Я не могу обработать тип контента "{{ field.type }}"
            </h4>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            v-if="commitState == 0"
            type="submit"
            class="btn btn-primary w-100"
          >
            Обновить
          </button>
          <button v-else-if="commitState === 1" class="btn btn-secondary w-100">
            <span>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="sr-only">Обновление...</span>
            </span>
          </button>
          <button v-else-if="commitState === 2" class="btn btn-success w-100">
            Успешно обновлено!
          </button>
          <button v-else-if="commitState === 3" class="btn btn-danger w-100">
            Ошибка!
          </button>
        </div>
      </div>
    </form>
  </div>
  <div v-else class="center">
    Ошибка
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import LinkInput from "@/components/LinkInput.vue";
import ArrayInput from "@/components/ArrayInput.vue";
import DateInput from "@/components/DateInput.vue";
import BooleanInput from "@/components/BooleanInput.vue";
import AssetInput from "@/components/AssetInput.vue";
import LocationInput from "@/components/LocationInput.vue";

export default {
  components: {
    TextInput,
    LinkInput,
    ArrayInput,
    DateInput,
    BooleanInput,
    AssetInput,
    LocationInput
  },
  data() {
    return {
      spaceID: this.$route.params.space,
      contentType: this.$route.params.type,
      enviromentName: this.$route.params.env,
      entryId: this.$route.params.content,
      enviroment: null,
      fields: [],
      contentTypeData: [],
      valid: false,
      loading: true,
      id2type: {},
      loadingClasses: [
        "text-primary",
        "text-secondary",
        "text-success",
        "text-danger",
        "text-warning",
        "text-info",
        "text-dark"
      ],
      state: "Инициализация...",
      content: {},
      form: {},
      interfaceData: null,
      disabled: false,
      commitState: 0
    };
  },
  async mounted() {
    const client = this.$createClient();
    try {
      this.state = "Получаем настройки пространства...";
      const space = await client.getSpace(this.spaceID);
      this.state = "Получаем настройки окружения...";
      this.enviroment = await space.getEnvironment(this.enviromentName);
      this.state = "Получаем тип контента...";
      this.contentTypeData = await this.enviroment.getContentType(
        this.contentType
      );
      this.state = "Получаем контент...";
      this.content = await this.enviroment.getEntry(this.entryId);
      this.fields = this.contentTypeData.fields;
      for (let i of this.fields) {
        this.id2type[i.id] = i.type || null;
      }
      for (let index in this.fields) {
        this.form[this.fields[index].id] =
          this.content.fields[this.fields[index].id] || {};
      }
      this.state = "Получаем настройки интерфейса...";
      this.interfaceData = await this.contentTypeData.getEditorInterface();
      this.valid = true;
      this.state = "Готово!";
    } catch (error) {
      this.valid = false;
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async submit() {
      const form = JSON.parse(JSON.stringify(this.form));
      if (this.disabled) return;
      this.disabled = true;
      try {
        this.commitState = 1;
        this.disabled = true;
        this.content.fields = form
        this.content = await this.content.update(this.content);
        for (let index in this.fields) {
          this.form[this.fields[index].id] =
            this.content.fields[this.fields[index].id] || {};
        }
        this.commitState = 2;
      } catch (error) {
        this.commitState = 3;
        throw error;
      } finally {
        this.disabled = false;
        setTimeout(
          () => (this.commitState = 0),
          this.commitState === 2 ? 2000 : 4000
        );
      }
    }
  }
};
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.close-link {
  text-decoration: inherit;
}
</style>
