<template>
  <div>
    <h4 v-if="field.name">{{ field.name }}:</h4>
    <template
      v-if="
        field.linkType === 'Entry' &&
          (control.widgetId === 'entryLinkEditor' ||
            control.widgetId === 'entryLinksEditor')
      "
    >
      <button
        v-if="!loading"
        :class="[
          'btn',
          value && (value.sys?.id || (isArray && value.length > 0)) ? 'btn-primary' : 'btn-secondary',
          'w-100'
        ]"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="`#collapse_link_${field.id}`"
        aria-expanded="false"
        :aria-controls="`collapse_link_${field.id}`"
        :disabled="disabled"
      >
        {{
          value && (value.sys?.id || (isArray && value.length > 0))
            ? isArray
              ? "Выбраны элемент(ы)"
              : "Выбран элемент: "
            : "Не выбрано"
        }}
        {{ value && value?.sys?.id ? value.sys.id : "" }}
      </button>
      <button v-else class="btn btn-secondary w-100" type="button" disabled>
        <span>Загрузка...</span>
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
      <div class="collapse" :id="`collapse_link_${field.id}`">
        <div class="container-fluid">
          <div class="row" v-for="entry in content" :key="entry.sys.id">
            <div class="col">
              <div class="card mb-2 mt-2">
                <div class="card-body d-flex justify-content-between flex-wrap">
                  <h5 class="card-title">
                    {{
                      type2name[entry.sys.contentType.sys.id]
                        ? entry.fields[type2name[entry.sys.contentType.sys.id]][
                            $route.params.locale
                          ]
                        : "Без названия"
                    }}
                  </h5>
                  <div>
                    <button
                      v-if="!isSelected(entry.sys.id)"
                      @click.stop.prevent="select(entry.sys.id)"
                      type="button"
                      class="btn btn-primary m-1"
                      data-bs-toggle="collapse"
                      :data-bs-target="!isArray && `#collapse_link_${field.id}`"
                      aria-expanded="false"
                      :aria-controls="!isArray && `collapse_link_${field.id}`"
                      :disabled="disabled"
                    >
                      Использовать
                    </button>
                    <button
                      v-else
                      @click.stop.prevent="select(entry.sys.id)"
                      type="button"
                      class="btn btn-success m-1"
                      data-bs-toggle="collapse"
                      :data-bs-target="!isArray && `#collapse_link_${field.id}`"
                      aria-expanded="false"
                      :aria-controls="!isArray && `collapse_link_${field.id}`"
                      :disabled="disabled"
                    >
                      Использовано
                    </button>
                  </div>
                </div>
                <div class="card-footer">
                  <small class="text-muted"
                    >Последнее обновление
                    {{
                      $moment(entry.sys.updatedAt)
                        .locale("ru")
                        .fromNow()
                    }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      Неизвестный тип ссылки: {{ field.linkType }}+{{ control.widgetId }}
    </template>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    control: {
      type: Object,
      required: true
    },
    value: {
      type: undefined,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      content: [],
      type2name: {},
      isArray: false
    };
  },
  async mounted() {
    if (
      this.field.linkType !== "Entry" ||
      (this.control.widgetId !== "entryLinkEditor" &&
        this.control.widgetId !== "entryLinksEditor")
    )
      return;
    if (this.control.widgetId === "entryLinksEditor") {
      this.isArray = true;
    }

    const client = this.$createClient();
    const space = await client.getSpace(this.$route.params.space);
    const enviroment = await space.getEnvironment(this.$route.params.env);

    const contentTypes = await enviroment.getContentTypes().then(e =>
      e.items.map(c => ({
        name: c.name,
        id: c.sys.id,
        nameBy: c.displayField
      }))
    );
    for (let i of contentTypes) {
      this.type2name[i.id] = i.nameBy || null;
    }

    const linkContentType = this.field.validations.find(
      e => !!e.linkContentType
    )?.linkContentType;

    this.content = await enviroment
      .getEntries({
        content_type:
          !linkContentType || linkContentType.length > 1
            ? undefined
            : linkContentType[0]
      })
      .then(e =>
        linkContentType
          ? e.items.filter(i =>
              linkContentType.includes(i.sys.contentType.sys.id)
            )
          : e.items
      );

    this.loading = false;
  },
  methods: {
    isSelected(id) {
      if (this.isArray) {
        return this.value?.some(e => e?.sys?.id === id);
      } else {
        return this.value?.sys?.id === id;
      }
    },
    select(id) {
      if (this.isArray) {
        if (this.isSelected(id)) {
          this.$emit(
            "update:value",
            this.value.filter(e => e.sys.id !== id)
          );
        } else {
          this.$emit("update:value", [
            ...this.value,
            {
              sys: {
                type: "Link",
                linkType: "Entry",
                id
              }
            }
          ]);
        }
      } else {
        this.$emit("update:value", {
          sys: {
            type: "Link",
            linkType: "Entry",
            id
          }
        });
      }
    }
  },
  emits: ["update:value"]
};
</script>
