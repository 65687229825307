<template>
  <div v-if="loading" class="center">
    <div
      :class="[
        'spinner-border',
        loadingClasses[Math.floor(Math.random() * loadingClasses.length)],
      ]"
      style="width: 3rem; height: 3rem"
      role="status"
    />
    <h5>{{ state }}</h5>
  </div>
  <div v-else-if="valid">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          :class="['nav-link', { active: contentType.id === curContent }]"
          :id="`nav-${contentType.id}-tab`"
          data-bs-toggle="tab"
          :data-bs-target="
            contentType.DOMID
              ? `#${contentType.DOMID}`
              : `#nav-${contentType.id}`
          "
          type="button"
          role="tab"
          :aria-controls="`nav-${contentType.id}`"
          aria-selected="false"
          v-for="contentType in contentTypes"
          :key="`btn_${contentType.id}`"
          @click="route(contentType.id)"
        >
          {{ contentType.name }}
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        :class="
          contentType.id === curContent
            ? ['tab-pane', 'fade', 'show', 'active']
            : ['tab-pane', 'fade']
        "
        :id="contentType.DOMID || `nav-${contentType.id}`"
        role="tabpanel"
        :aria-labelledby="`nav-${contentType.id}-tab`"
        v-for="contentType in contentTypes"
        :key="`tab_${contentType.id}`"
      >
        <ContentEdit
          v-if="!contentType.assets"
          @updateEntries="updateEntries"
          :enviroment="enviroment"
          :contentType="contentType.id"
          :ref="addRef"
          :type2name="type2name"
        />
        <AssetsEdit v-else :enviroment="enviroment" :space="space" />
      </div>
    </div>
  </div>
  <div v-else class="center">Ошибка</div>
</template>

<script>
import ContentEdit from "@/components/ContentEdit.vue";
import AssetsEdit from "@/components/AssetsEdit.vue";

export default {
  components: {
    ContentEdit,
    AssetsEdit,
  },
  data() {
    return {
      spaceID: this.$route.params.space,
      enviromentName: this.$route.params.env,
      curContent: this.$route.params.type,
      enviroment: null,
      space: null,
      contentTypes: [],
      type2name: {},
      valid: false,
      loading: true,
      loadingClasses: [
        "text-primary",
        "text-secondary",
        "text-success",
        "text-danger",
        "text-warning",
        "text-info",
        "text-dark",
      ],
      ContentEditRefs: [],
      state: "Инициализация...",
    };
  },
  async mounted() {
    const client = this.$createClient();
    try {
      this.state = "Получаем настройки пространства...";
      this.space = await client.getSpace(this.spaceID);
      this.state = "Получаем настройки окружения...";
      this.enviroment = await this.space.getEnvironment(this.enviromentName);
      this.state = "Получаем типы контента...";
      this.contentTypes = await this.enviroment.getContentTypes().then((e) => [
        { name: "Весь контент", id: "" },
        ...e.items.map((c) => ({
          name: c.name,
          id: c.sys.id,
          nameBy: c.displayField,
        })),
        { name: "Ассеты", id: "@assets", DOMID: "assets", assets: true },
      ]);
      for (let i of this.contentTypes) {
        this.type2name[i.id] = i.nameBy || null;
      }
      if (!this.type2name[this.curContent] && this.curContent !== "@assets") {
        this.$router.push(
          `/dashboard/${this.$route.params.space}/${this.$route.params.env}/${this.$route.params.locale}`
        );
        this.curContent = "";
      }
      this.valid = true;
      this.state = "Готово!";
    } catch (error) {
      this.valid = false;
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  beforeUpdate() {
    this.ContentEditRefs = [];
  },
  methods: {
    updateEntries() {
      this.ContentEditRefs.forEach((ref) => ref.update());
    },
    addRef(el) {
      if (el) {
        this.ContentEditRefs.push(el);
      }
    },
    route(id) {
      this.$router.push(
        `/dashboard/${this.$route.params.space}/${this.$route.params.env}/${this.$route.params.locale}/${id}`
      );
    },
  },
};
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
