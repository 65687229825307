<template>
  <div>
    <template v-if="field.type === 'Array'">
      <div
        class="container-fluid"
        v-if="
          ['Number', 'Integer', 'Text', 'Symbol', 'RichText'].includes(
            field.items.type
          )
        "
      >
        <div class="row mb-2 justify-content-between">
          <div class="col-md-auto">
            <h4>{{ field.name }}:</h4>
          </div>
          <div class="col-md-auto btn btn-success" @click="add">Добавить</div>
        </div>
        <div
          class="row mb-2 align-items-center"
          v-for="(item, i) in value"
          :key="`arr_${i}`"
        >
          <div class="col-1">
            <h5>{{ i }}</h5>
          </div>
          <div class="col">
            <TextInput
              :field="{ ...field.items, name: '', id: `${field.id}_${i}` }"
              :control="control"
              :disabled="disabled"
              :value="value[i]"
              @update:value="update($event, i)"
            />
          </div>
          <div class="col-md-auto">
            <button type="button" class="btn-close" @click="del(i)"></button>
          </div>
        </div>
      </div>
      <LinkInput
        :field="{ ...field.items, name: field.name, id: field.id, required: field.required }"
        :control="control"
        :disabled="disabled"
        :value="value"
        @update:value="$emit('update:value', $event)"
        v-else-if="
          ['Link'].includes(field.items.type) &&
            field.items.linkType === 'Entry'
        "
      />
      <AssetInput
        :field="{ ...field.items, name: field.name, id: field.id, required: field.required }"
        :control="control"
        :disabled="disabled"
        :value="value"
        @update:value="$emit('update:value', $event)"
        v-else-if="
          ['Link'].includes(field.items.type) &&
            field.items.linkType === 'Asset'
        "
      />
    </template>
  </div>
</template>

<script>
import TextInput from "./TextInput.vue";
import LinkInput from "./LinkInput.vue";
import AssetInput from "./AssetInput.vue";

export default {
  components: {
    TextInput,
    LinkInput,
    AssetInput
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    control: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    update(val, i) {
      const value = this.value.slice();
      value[i] = val;
      this.$emit("update:value", value);
    },
    del(i) {
      this.$emit(
        "update:value",
        this.value.filter((e, index) => index !== i)
      );
    },
    add() {
      const value = this.value.slice();
      value.push(undefined);
      this.$emit("update:value", value);
    }
  },
  emits: ["update:value"]
};
</script>
