<template>
  <div>
    <template v-if="control.widgetId === 'datePicker'">
      <label v-if="field.name" class="form-label"
        ><h4>{{ field.name }}:</h4></label
      >
      <div class="row justify-content-center">
        <div class="col">
          <input
            class="form-control"
            :required="field.required"
            @input="
              $emit('update:value', $moment($event.target.value).toISOString())
            "
            type="date"
            :disabled="disabled"
            :value="$moment(value).format('YYYY-MM-DD')"
          />
        </div>
      </div>
    </template>
    <template v-else> Неизвестный тип виджета: {{ control }}. </template>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    control: {
      type: Object,
      required: true,
    },
    value: {
      type: undefined,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:value"],
};
</script>
