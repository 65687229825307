<template>
  <div v-if="loading" class="center">
    <div
      :class="[
        'spinner-border',
        loadingClasses[Math.floor(Math.random() * loadingClasses.length)]
      ]"
      style="width: 3rem; height: 3rem;"
      role="status"
    />
    <h5>Получение списка пространств...</h5>
  </div>
  <div v-else class="card-group">
    <div
      class="card"
      v-for="space in spaces"
      :key="space.sys.id"
    >
      <div class="card-body">
        <h5 class="card-title">{{ space.name }}</h5>
        <router-link class="btn btn-primary" :to="`/dashboard/${space.sys.id}/master/en-US`">Редактировать</router-link>
      </div>
      <div class="card-footer">
        <small class="text-muted"
          >Последнее обновление
          {{
            $moment(space.sys.updatedAt)
              .locale("ru")
              .fromNow()
          }}</small
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      loadingClasses: [
        "text-primary",
        "text-secondary",
        "text-success",
        "text-danger",
        "text-warning",
        "text-info",
        "text-dark"
      ],
      spaces: [],
      client: null
    };
  },
  async mounted() {
    this.client = this.$createClient();
    try {
      this.spaces = await this.client.getSpaces().then(e => e.items);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (min-width: 576px) {
  .card-group {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    transform: translate(0px, -50%);
  }
}
</style>
