<template>
  <label v-if="field.name" class="form-label"
    ><h4>{{ field.name }}:</h4></label
  >
  <div ref="map" class="input_map"></div>
</template>

<script>
import leaflet from "leaflet";

export default {
  data() {
    return {
      map: null,
      marker: null,
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    control: {
      type: Object,
      required: true,
    },
    value: {
      type: undefined,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.marker = leaflet.marker();
    this.map = leaflet
      .map(this.$refs.map)
      .setView(
        this.value ? [this.value.lat, this.value.lon] : [0, 0],
        this.value ? 13 : 1
      );
    leaflet
      .tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: "mapbox/streets-v11",
          tileSize: 512,
          zoomOffset: -1,
          accessToken:
            process.env.VUE_APP_MAPBOX_TOKEN,
        }
      )
      .addTo(this.map);
    if (this.value) {
      this.handleClick({
        latlng: { lat: this.value.lat, lng: this.value.lon },
      });
    }
    this.map.on("click", this.handleClick);
  },
  methods: {
    handleClick(e) {
      this.marker.setLatLng(e.latlng).addTo(this.map);
      this.$emit("update:value", { lat: e.latlng.lat, lon: e.latlng.lng });
    },
  },
  emits: ["update:value"],
};
</script>

<style scoped>
.input_map {
  height: 450px;
}
</style>
